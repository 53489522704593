import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import axios from "axios";

class InventoryList extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");
    // if (!token) {
    //   this.props.history.push("/login");
    // }

    this.state = {
      token: token,
      items: [],
      states: [],
      msa: [],
      hospitals: [],
    };

    this.handleStateChange = this.handleStateChange.bind(this);
  }

  refreshToken() {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const expired = localStorage.getItem("expired");

    if (token !== null && refreshToken !== null && expired !== null) {
      axios
        .post(`${process.env.REACT_APP_API_URL}api/auth/refreshToken`, {
          token: token,
          refreshToken: refreshToken,
          expiration: expired,
        })
        .then((response) => {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expired");
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("expired", response.data.expiration);
        })
        .catch((error) => {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            localStorage.removeItem("token");
            localStorage.removeItem("email");
            localStorage.removeItem("userID");
            localStorage.removeItem("userName");
            localStorage.removeItem("secret_key");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("expired");
            this.props.history.push("/login");
          }
        });
    }
  }

  getInventoryList() {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/Inventory/InventoryListings`, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
          secret_key: localStorage.getItem("secret_key"),
        },
      })
      .then((response) => {
        this.setState({
          items: Object.values(response.data),
        });
      })
      .catch((error) => {
        if (
          error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          localStorage.removeItem("userID");
          localStorage.removeItem("userName");
          localStorage.removeItem("secret_key");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expired");
          this.props.history.push("/login");
        }
      });
  }

  getAllState() {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/master/getallstates`, {})
      .then((response) => {
        this.setState({
          states: Object.values(response.data),
        });
      })
      .catch((error) => {
        if (
          error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          localStorage.removeItem("userID");
          localStorage.removeItem("userName");
          localStorage.removeItem("secret_key");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expired");
          this.props.history.push("/login");
        }
      });
  }
  getAllMsa() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/inventory/getmsabystatename?state={stateName:''}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            secret_key: localStorage.getItem("secret_key")
          },
        }
      )
      .then((response) => {
        this.setState({
          msa: Object.values(response.data),
        });
      })
      .catch((error) => {
        if (
          error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          localStorage.removeItem("userID");
          localStorage.removeItem("userName");
          localStorage.removeItem("secret_key");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expired");
          this.props.history.push("/login");
        }
      });
  }



  handleStateChange(event) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/inventory/getmsabystatename?state={stateName:'${event.target.value}'}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            secret_key: localStorage.getItem("secret_key"),
          },
        }
      )
      .then((response) => {
        this.setState({
          msa: Object.values(response.data),
        });
      })
      .catch((error) => {
        if (
          error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          localStorage.removeItem("userID");
          localStorage.removeItem("userName");
          localStorage.removeItem("secret_key");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expired");
          this.props.history.push("/login");
        }
      });
    // if (event.target.value !== "") {
    //   axios
    //     .get(
    //       `${process.env.REACT_APP_API_URL}api/Inventory/InventoryListings?stateName=${event.target.value}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${this.state.token}`,
    //           secret_key: localStorage.getItem("secret_key"),
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       this.setState({
    //         items: Object.values(response.data),
    //       });
    //     })
    //     .catch((error) => {
    //       this.setState({
    //         items: "",
    //       });
    //     });
    // } else {
    //   this.getInventoryList();
    //}
  }

  componentDidMount() {
    //this.getInventoryList();
    this.getAllMsa();
    this.refreshToken();
    this.getAllState();
    // this.getAllHospital();
    const script = document.createElement("script");
    script.async = true;
    script.src = "/js/custom.js";
    this.div.appendChild(script);
  }

  render() {
    return (
      <>
        {this.state.token !== null ? <AfterLoginMenu /> : <BeforeLoginMenu />}
        <main role="main">
          <div className="gredient-bg pt-3 pb-5" ref={(el) => (this.div = el)}>
            <div className="container">
              <div className="row  new-invdeopdown-filters m-0 mb-3 justify-content-center">
                <div className="col-md-4 col-sm-12 inventory-filter-col pt-4 text-white  row align-items-center">
                  <label className="bold_text pr-3">
                    STATE NAME:
                  </label>
                  <select
                    className="mdb-select md-form drpdownList"
                    //onChange={this.handleStateChange}
                    id="stateList"
                  >
                    <option value="" selected>
                      Select State
                    </option>
                    {this.state.states.map((stateArr, index) => (
                      <option value={stateArr.stateName}>
                        {stateArr.stateName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 col-sm-12 inventory-filter-col pt-4 text-white    row align-items-center">
                  <label className="bold_text pr-3 ">
                    MSA:
                  </label>
                  <select
                    className="mdb-select md-form drpdownList"
                    //onChange={this.handleStateChange}
                    id="msaList"
                  >
                    <option value="" selected>
                      Select MSA
                    </option>
                    {this.state.msa.map((msaArr, index) => (
                      <option value={msaArr.msaID}>{msaArr.msaName}</option>
                    ))}
                  </select>

                </div>

                <div className="col-md-4 col-sm-12 inventory-filter-col pt-4 text-white   row align-items-center">
                  <label className="bold_text pr-3 ">
                    HOSPITAL:
                  </label>
                  <select
                    className="mdb-select md-form drpdownList"
                    //onChange={this.handleStateChange}
                    id="hospitalList"
                  >
                    <option value="" selected>
                      Select Hospital
                    </option>
                    {this.state.hospitals.map((itm, index) => (
                      <option value={itm.hospitalID} key={index}>{itm.facilityName}</option>
                    ))}
                  </select>

                </div>
                </div>

                <div className="input-group">
                  <div className="col-md-12">
                    <table
                      className="table table-borderless table-blue table table-striped table-bordered table-sm"
                      id="tbl_inventory"
                    //ref="inventoryTbl" 
                    >
                      <thead>
                        <tr>
                        <th scope="col">
                            State Name
                          </th>
                          <th scope="col">
                            MSA Name
                          </th>
                          <th scope="col">
                            Care Center
                          </th>
                          <th scope="col">
                            Facility Name
                          </th>
                          <th scope="col">
                            Has Files
                          </th>
                          <th scope="col">
                            Cash Rates
                          </th>
                          <th scope="col">
                            Commercial Rates
                          </th>
                          <th scope="col">
                            Medicare Rates
                          </th>
                          <th scope="col">
                            Inpatient
                          </th>
                          <th scope="col">
                            Outpatient
                          </th>
                          <th scope="col">
                          Drugs
                          </th>
                          {/*<th scope="col">
                            Quality Respondents
                          </th>
                          <th scope="col">
                            Quality Yes
                          </th>
                          <th scope="col">
                            Quality No
                          </th>
                          <th scope="col">
                            Price Accuracy Respondents
                          </th>
                          <th scope="col">
                            Price Accuracy Yes
                          </th>
                          <th scope="col">
                            Price Accuracy No
                          </th>
                          <th scope="col">
                            OON Bill Respondents
                          </th>
                          <th scope="col">
                            OON Bill Yes
                          </th>
                          <th scope="col">
                            OON Bill No
                          </th>
                          <th scope="col">
                            GFE Respondents
                          </th>
                          <th scope="col">
                            GFE Yes
                          </th>
                          <th scope="col">
                            GFE No
                          </th>
                          <th scope="col">
                            Medical Codes Respondents
                          </th>
                          <th scope="col">
                            Medical Codes Yes
                          </th>
                          <th scope="col">
                            Medical Codes No
                          </th>
                          <th scope="col">
                            Baby delivery Respondents
                          </th>
                          <th scope="col">
                            Baby delivery Vaginal
                          </th>
                          <th scope="col">
                            Baby delivery C-Section
                          </th>
                          <th scope="col">
                            Referrals Respondents
                          </th>
                          <th scope="col">
                            Referrals Yes
                          </th>
                          <th scope="col">
                            Referrals No
                          </th>
                          <th scope="col">
                            Cancer Respondents
                          </th>
                          <th scope="col">
                            Cancer Yes
                          </th>
                          <th scope="col">
                            Cancer No
                    </th>*/}
                        </tr>
                      </thead>
                      <tbody>
                        {/* {this.state.items.map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {item.careCenter}
                                                        </td>
                                                        <td>
                                                            {item.facilityName}
                                                        </td>
                                                        <td className="text-center">
                                                            {item.hasFiles == false
                                                                ? "No"
                                                                : "Yes"}
                                                        </td>
                                                        <td className="text-center">
                                                            {item.cashRates ==
                                                            false
                                                                ? "No"
                                                                : "Yes"}
                                                        </td>
                                                        <td className="text-center">
                                                            {item.commercialRates ==
                                                            false
                                                                ? "No"
                                                                : "Yes"}
                                                        </td>
                                                        <td className="text-center">
                                                            {item.medicareRates ==
                                                            false
                                                                ? "No"
                                                                : "Yes"}
                                                        </td>
                                                        <td className="text-center">
                                                            {item.inpatientCodes ==
                                                            false
                                                                ? "No"
                                                                : "Yes"}
                                                        </td>
                                                        <td className="text-center">
                                                            {item.outpatientCodes ==
                                                            false
                                                                ? "No"
                                                                : "Yes"}
                                                        </td>
                                                    </tr>
                                                )
                                            )} */}
                      </tbody>
                    </table>
                  </div>
                </div>
              
            </div>
          </div>
        </main>
      </>
    );
  }
}
export default withRouter(InventoryList);
